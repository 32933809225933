import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Tooltip } from "@mui/material";
import { UserProfileMenu } from "./UserProfileMenu";

export const UserProfile = (props) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <Tooltip title="User Profile">
        <IconButton
          size="large"
          onClick={handleOpenUserMenu}
          aria-label="show user toolkit menu"
          className="icon-button"
        >
          <FontAwesomeIcon icon={faUserCircle} className="icon-color" />
        </IconButton>
      </Tooltip>
      <UserProfileMenu
        anchorElUser={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
        userInfo={window.sessionStorage}
      />
    </>
  );
};
