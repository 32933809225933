export const buildDynamicElasticQuery = (filterObj) => {
  console.log("Filter obj --- ", filterObj);
  /*
   * {
   *   range:{
   *    fieldName:(name_of_field),
   *    gte: "val",
   *    lte: "val
   *   },
   *  terms:[
   *    {
   *      fieldName:"name_of_field",
   *      values: "actual_val"
   *    }
   * ]
   * }
   * */
  const { range, terms } = filterObj;
  let query = {
    size: "200",
    sort: [
      {
        errorDateTime: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: [],
      },
    },
  };
  function getRangeQuery() {
    const { fieldName, gte, lte } = range;
    let rangeQuery = {
      range: {},
    };
    rangeQuery["range"][fieldName] = {
      gte: gte,
      lte: lte,
    };
    return rangeQuery;
  }

  function getTerms() {
    if (terms) {
      return terms.map((field) => {
        let terms = {
          terms: {},
        };
        terms["terms"][field.fieldName] = field.values;
        return terms;
      });
    }
    return [];
  }

  query.query.bool.must = [range && getRangeQuery(), ...getTerms()];
  return query;
};
