import { GEO_SEARCH_BY_IMPORT_CODE } from "../constants/QueryConstants";
import axios from "axios";
import { ES_AUTH_HEADER, GEO_LOCATION_ES_URL } from "../constants/URL";

export const getOptionsAsync = async (query) => {
  console.log("In async");
  const searchQuery = GEO_SEARCH_BY_IMPORT_CODE(query, "10");
  const response = await axios.get(
    `${GEO_LOCATION_ES_URL}&source=${searchQuery}`,
    {
      headers: ES_AUTH_HEADER,
    }
  );
  return response.data;
};

export const getEsDataAsync = async (url, query) => {
  const response = await axios.get(`${url}&source=${query}`, {
    headers: ES_AUTH_HEADER,
  });
  return response.data;
};
