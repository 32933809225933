import React from "react";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import Iconify from "../../base-components/Iconify";

export const UserNotificationListItem = (props) => {
  const { notification } = props;
  return (
    <>
      <ListItemButton
        disableGutters
        component="h6"
        //href="#simple-list"
        //selected
        sx={{ py: 1.5, px: 2.5, mt: "1px", bgcolor: "#f1f9f1" }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "green" }}>
            <AddLocationIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle2">
              {notification.description}
            </Typography>
          }
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Iconify
                icon="eva:clock-fill"
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              about&nbsp;
              {formatDistanceToNow(new Date(notification.createdDateTime))}
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
};
