import {
  faChartLine,
  faListOl,
  faSearchLocation,
  faSquare,
  faTags,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";

export const PreviewFeatureConstants = [
  {
    id: 1,
    label: "Site Label",
    icon: faToggleOff,
    tooltip: "This toggle button disables the label on the site marker.",
  },
  {
    id: 2,
    label: "Cluster View",
    icon: faToggleOff,
    tooltip: "Toggle between a cluster view and a site level view.",
  },
  {
    id: 3,
    label: "List View",
    icon: faListOl,
    tooltip:
      "View the sites on the map view port or the result of a search as a list.",
  },
  {
    id: 4,
    label: "KPI Charts",
    icon: faChartLine,
    tooltip: "KPI Charts feature.",
  },
  {
    id: 5,
    label: "Advance Search",
    icon: faSearchLocation,
    tooltip: "Advance Search feature.",
  },
  {
    id: 6,
    label: "Discount/Loyalty",
    icon: faTags,
    tooltip: "Discount/Loyalty",
  },
  {
    id: 7,
    label: "User Defined Clusters",
    icon: faSquare,
    tooltip: "User Defined Clusters",
  },
];
