import React, { useState } from "react";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { ErrorNotificationMenu } from "./ErrorNotificationMenu";

export const ErrorNotifications = (props) => {
  const [anchorElError, setAnchorElError] = useState(null);
  const handleOpenErrorNotificationsMenu = (event) => {
    setAnchorElError(event.currentTarget);
  };
  const handleCloseErrorNotificationsMenu = () => {
    setAnchorElError(null);
  };
  return (
    <>
      <Tooltip title="Error Notifications">
        <IconButton
          size="large"
          aria-label="show error notifications"
          className="icon-button"
          onClick={handleOpenErrorNotificationsMenu}
        >
          <Badge badgeContent={4} color="error">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="icon-color"
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <ErrorNotificationMenu
        anchorElError={anchorElError}
        handleCloseErrorNotificationsMenu={handleCloseErrorNotificationsMenu}
      />
    </>
  );
};
