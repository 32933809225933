import { List, ListItem, ListItemText } from "@mui/material";
import { ErrorListItems } from "./ErrorListItems";

export const DetailsViewList = (props) => {
  const { errorData } = props;
  return (
    <List
      sx={{
        width: "100%",
        maxHeight: "200px",
        ".section::-webkit-scrollbar-track": {
          backgroundColor: "#e4e4e4",
          borderRadius: "100px",
        },
      }}
    >
      {errorData && errorData.length > 0 ? (
        <ErrorListItems errorData={errorData} />
      ) : (
        <ListItem alignItems="flex-start">
          <ListItemText primary="No errors found....!!!" />
        </ListItem>
      )}
    </List>
  );
};
