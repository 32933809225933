import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useAxiosGET } from "../../../util/getCallHook";
import { format, subDays, subHours } from "date-fns";
import { SiteTypeAhead } from "../../base-components/SiteTypeAhead";
import { ErrorTabs } from "./ErrorTabs";
import { buildDynamicElasticQuery } from "../../../util/buildDynamicElasticQuery";
import { getEsDataAsync } from "../../../util/getOptions";
import { PRICING_ERROR_ES_URL } from "../../../constants/URL";

export const ErrorNotificationMenu = (props) => {
  const { anchorElError, handleCloseErrorNotificationsMenu } = props;
  const [errorCodes, setErrorCodes] = useState([]);
  const [businessFunctions, setBusinessFunctions] = useState([]);
  const [fromDate, setFromDate] = useState(
    format(subHours(new Date(), 4), "yyyy-MM-dd'T'HH:mm:ss")
  );
  const [toDate, setToDate] = useState(
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
  );
  const [errorData, setErrorData] = useState([]);
  const { response: errorCodesList } = useAxiosGET(
    "https:///api.7ep-dev.7-eleven.com/fuels/pricing/fpcc-store-location-util/getPricingErrorCodes",
    null
  );
  const { response: busFunctionList } = useAxiosGET(
    "https:///api.7ep-dev.7-eleven.com/fuels/pricing/fpcc-store-location-util/getPricingErrorBusinessFunctions",
    null
  );
  const handleDateChange = (ev) => {
    console.log("Event --- ", ev.target.value);
    if (ev.target.id === "date-from") {
      setFromDate(ev.target.value);
    } else {
      setToDate(ev.target.value);
    }
  };
  const handleErrorCodeChange = (ev, val) => {
    let codeList = val.map((error) => error.errorCode);
    console.log("Error list -> ", codeList);
    setErrorCodes(codeList);
  };
  const handleApply = () => {
    let filterObj = {
      range: {
        fieldName: "errorDateTime",
        gte: fromDate,
        lte: toDate,
      },
    };
    let termArr = [];
    if (errorCodes && errorCodes.length > 0) {
      termArr.push({
        fieldName: "errorCode",
        values: errorCodes,
      });
    }
    if (businessFunctions && businessFunctions.length > 0) {
      termArr.push({
        fieldName: "businessFunction",
        values: businessFunctions,
      });
    }
    if (termArr.length > 0) {
      filterObj["terms"] = termArr;
    }
    const query = buildDynamicElasticQuery(filterObj);
    console.log("Formed query --- ", JSON.stringify(query));
    getEsDataAsync(PRICING_ERROR_ES_URL, JSON.stringify(query))
      .then((result) => {
        console.log("Result -- ", result);
        let errorData = result.hits.hits.map((hit) => hit._source);
        setErrorData(errorData);
      })
      .catch((err) => {
        console.log("error -- ", err);
      });
  };
  return (
    <>
      <Popover
        id="error-notifications-menu"
        open={Boolean(anchorElError)}
        anchorEl={anchorElError}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseErrorNotificationsMenu}
        //onClick={handleCloseErrorNotificationsMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            width: 550,
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 0.5,
            px: 1,
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" align="center" color="green">
            Pricing Errors
          </Typography>
        </Box>
        <Divider />
        <Container sx={{ py: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                onChange={handleErrorCodeChange}
                size="small"
                limitTags={1}
                getOptionLabel={(option) =>
                  option.errorCode + " - " + option.error_description
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Error Codes"
                    placeholder="Select error codes"
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option.errorCode}
                      size="small"
                    />
                  ));
                }}
                options={errorCodesList}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                size="small"
                limitTags={1}
                onChange={(ev, val) => setBusinessFunctions(val)}
                getOptionLabel={(option) => option}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business Function"
                    placeholder="Select business functions"
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option}
                      size="small"
                    />
                  ));
                }}
                options={busFunctionList}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="date-from"
                type="datetime-local"
                size="small"
                fullWidth
                onChange={handleDateChange}
                value={fromDate}
                //defaultValue={}
                inputProps={{
                  min: format(subDays(new Date(), 6), "yyyy-MM-dd'T'HH:mm"),
                  max: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="date-to"
                type="datetime-local"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleDateChange}
                value={toDate}
                inputProps={{
                  min: format(subDays(new Date(), 6), "yyyy-MM-dd'T'HH:mm"),
                  max: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SiteTypeAhead />
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                color="success"
                variant="contained"
                size="medium"
                fullWidth
                onClick={handleApply}
              >
                Apply
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button color="error" variant="outlined" size="medium" fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ py: "5px" }}>
          <ErrorTabs errorData={errorData} />
        </Container>
      </Popover>
    </>
  );
};
