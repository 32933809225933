import React from "react";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

export const Announcements = (props) => {
  return (
    <Tooltip title="Announcements">
      <IconButton
        size="large"
        aria-label="show announcements"
        className="icon-button"
      >
        <Badge badgeContent={1} color="error">
          <FontAwesomeIcon icon={faBullhorn} className="icon-color" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
