import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import EmailIcon from "@mui/icons-material/Email";
import GavelIcon from "@mui/icons-material/Gavel";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import StoreIcon from "@mui/icons-material/Store";

export const businessFunIconMap = {
  PriceUpdate: <AttachMoneyIcon fontSize="medium" />,
  PriceFeedIntegration: <RssFeedIcon fontSize="medium" />,
  Transaction: <PointOfSaleIcon fontSize="medium" />,
  TankInventory: <LocalShippingIcon fontSize="medium" />,
  SalesSummary: <SummarizeIcon fontSize="medium" />,
  PostToA2I: <ImportExportIcon fontSize="medium" />,
  PriceUpdateSchdEmail: <EmailIcon fontSize="medium" />,
  productmappingfailures: <GavelIcon fontSize="medium" />,
  Transactions: <PointOfSaleIcon fontSize="medium" />,
  PriceUpdateAckTOA2I: <PriceChangeIcon fontSize="medium" />,
  StoreConnectivity: <StoreIcon fontSize="medium" />,
};
