import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./_previewcontent.scss";

export const PreviewContent = (props) => {
  const { previewData, handleChange } = props;
  return (
    <>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          {previewData.map((data) => {
            return (
              <FormControlLabel
                key={data.id}
                control={
                  <Checkbox
                    defaultChecked
                    name={data.label}
                    color="success"
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    {data.label}
                    <FontAwesomeIcon
                      icon={data.icon}
                      size="xl"
                      fixedWidth
                      className="preview-icon"
                    />
                  </>
                }
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </>
  );
};
