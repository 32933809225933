import { useEffect, useState } from "react";
import axios from "axios";

export const useAxiosPostMongo = (url, reqData) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const fetchData = () => {
    axios
      .post(url, reqData)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return { response, error, loading };
};
