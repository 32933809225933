import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { businessFunIconMap } from "../../../constants/ApplicationConstant";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

export const ErrorListItems = (props) => {
  const { errorData } = props;
  return errorData.map((error) => (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>{businessFunIconMap[error.businessFunction]}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography
              variant="subtitle2"
              component="span"
              sx={{ fontWeight: "bold", width: "5px", display: "inline" }}
            >
              {`${error.storeId}(${error.errorCode})`}
            </Typography>
            <Typography
              variant="subtitle2"
              component="span"
              sx={{
                display: "inline",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >{` - ${error.errorDescription}`}</Typography>
          </>
        }
        secondary={
          <>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {formatDistanceToNow(new Date(error.errorDateTime))}
            </Typography>
          </>
        }
      />
    </ListItem>
  ));
};
