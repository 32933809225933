import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PreviewContent } from "./PreviewContent";
import { PreviewFeatureConstants } from "../../../../constants/PreviewFeatureConstants";
import "./_previewfeature.scss";

export const PreviewFeature = (props) => {
  const { isPreviewVisible, setIsPreviewVisible } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  console.log("Preview flag --- ", isPreviewVisible);
  const handleClose = () => {
    setIsPreviewVisible(!isPreviewVisible);
  };
  const handlePreviewFeatureChange = (ev) => {
    console.log("Event --- ", ev.target);
  };
  return (
    <Dialog
      open={isPreviewVisible}
      maxWidth="xs"
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Typography className="modal-title">Preview Features</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <PreviewContent
          previewData={PreviewFeatureConstants}
          handleChange={handlePreviewFeatureChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="outlined"
          size="small"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button color="success" variant="contained" size="small" disabled>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
