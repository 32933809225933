import { DetailsViewList } from "./DetailsViewList";

export const TabPanel = (props) => {
  const { errorData, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <DetailsViewList errorData={errorData} />
        // <Box sx={{ p: 1 }}>
        //
        // </Box>
      )}
    </div>
  );
};
