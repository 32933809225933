import React, { useState } from "react";
import { Tab, Tabs, useTheme } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { TabPanel } from "./TabPanel";

export const ErrorTabs = (props) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (ev, val) => {
    console.log("Val --- ", val);
    setSelectedTab(val);
  };
  return (
    <>
      <Tabs
        //centered
        variant="fullWidth"
        value={selectedTab}
        onChange={handleChange}
        sx={{
          ".MuiTabs-indicator": {
            backgroundColor: "green",
          },
          ".Mui-selected": {
            color: "green !important",
          },
        }}
        aria-label="secondary tabs example"
      >
        <Tab value={0} label="Detailed View" />
        <Tab value={1} label="Aggregate View" />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={selectedTab}
      >
        <TabPanel
          value={selectedTab}
          index={0}
          dir={theme.direction}
          errorData={props.errorData}
        />
        <TabPanel value={selectedTab} index={1} dir={theme.direction} />
      </SwipeableViews>
    </>
  );
};
