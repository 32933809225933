export const GEO_SEARCH_BY_IMPORT_CODE = (searchKey, size) => {
  return JSON.stringify({
    size: `${size}`,
    query: {
      bool: {
        should: [
          {
            wildcard: {
              importcode: {
                value: `${searchKey}`,
              },
            },
          },
          {
            wildcard: {
              displayname: {
                value: `${searchKey}`,
              },
            },
          },
        ],
      },
    },
  });
};
