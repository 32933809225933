import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getOptionsAsync } from "../../util/getOptions";
import { debounce } from "lodash";

export const SiteTypeAhead = (props) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  /*const debounceFun = useCallback(debounce(hnadleDebounce, 100), []);*/

  const getOptions = useCallback(
    debounce((text, callback) => {
      setOptions([]);
      getOptionsAsync(`${text}*`).then(callback);
    }, 100),
    []
  );

  useEffect(() => {
    getOptions(inputValue, (fetchedOptions) => {
      console.log("Fetched options --- ", fetchedOptions);
      if (fetchedOptions && fetchedOptions.hits && fetchedOptions.hits.hits) {
        const optionsArr = fetchedOptions.hits.hits.map(
          (data) => data._source.importcode
        );
        setOptions(optionsArr);
      }
    });
  }, [inputValue, getOptions]);
  /*const handleChange = (event, val) => {
    //console.log("ev ---- ", ev);
    console.log("val --- ", val);
    debounce(() => {
      setOptions([]);
      getOptionsAsync(`${text}*`).then((res) => {
        console.log("Response --- ", res);
      });
    }, 100);
  };*/

  return (
    <Autocomplete
      fullWidth
      size="small"
      renderInput={(params) => (
        <TextField {...params} label="Sites" placeholder="Search sites....." />
      )}
      options={options}
      getOptionLabel={(option) => option}
      filterOptions={(x) => x}
      loading={options.length === 0}
      //onInputChange={handleChange}
      onInputChange={(e, newVal) => setInputValue(newVal)}
    />
  );
};
