import React from "react";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import logo from "../../assets/visopsSEILogo.png";
import "../styles/_ muiheader.scss";
import "../styles/_base.scss";
import { Announcements } from "./announcement/Announcements";
import { UserToolKit } from "./user-toolkit/UserToolKit";
import { ErrorNotifications } from "./error-notification/ErrorNotifications";
import { UserNotification } from "./user-notification/UserNotification";
import { UserProfile } from "./user-profile/UserProfile";
import { useAxiosPostMongo } from "../../util/postCallHook";

export const MaterialHeader = (props) => {
  console.log("React props --- ", props);
  const handleClick = () => {
    props.getStoreId(
      "2453",
      props.locationApiService,
      props.quickSearchService
    );
  };
  const { response: notifications } = useAxiosPostMongo(
    "https://webhooks.mongodb-realm.com/api/client/v2.0/app/auditlog-smumo/service/retrieveAuditLog/incoming_webhook/retrieveNotification?secret=fpccdev",
    {
      userId: "SubhamKumar.Biswas@7-11.com",
      createdDateTime: {
        $gte: "2022-02-19 12:45:56 +05:30",
      },
    }
  );
  return (
    <>
      <AppBar
        position="sticky"
        sx={{ bgcolor: "#f1f9f1" }}
        className="header-shadow"
      >
        <Toolbar>
          <img src={logo} alt="" className="header-logo" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Announcements />
            <UserToolKit />
            <ErrorNotifications />
            <UserNotification notifications={notifications} />
            <UserProfile />
            <Button onClick={handleClick}>Test</Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
