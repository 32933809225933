import React, { useState } from "react";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { UserNotificationMenu } from "./UserNotificationMenu";

export const UserNotification = (props) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserNotificationsMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserNotificationsMenu = () => {
    setAnchorElUser(null);
  };
  const { notifications } = props;
  return (
    <>
      <Tooltip title="User Notifications">
        <IconButton
          size="large"
          aria-label="show user notifications"
          className="icon-button"
          onClick={handleOpenUserNotificationsMenu}
        >
          <Badge
            badgeContent={notifications ? notifications.length : 0}
            color="error"
          >
            <FontAwesomeIcon icon={faBell} className="icon-color" />
          </Badge>
        </IconButton>
      </Tooltip>
      <UserNotificationMenu
        anchorElUser={anchorElUser}
        handleCloseUserNotificationsMenu={handleCloseUserNotificationsMenu}
        userInfo={window.sessionStorage}
        notifications={notifications}
      />
    </>
  );
};
