import React, { useState } from "react";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import Preview from "@mui/icons-material/Preview";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { PreviewFeature } from "./preview-feature/PreviewFeature";

export const UserProfileMenu = (props) => {
  const { anchorElUser, handleCloseUserMenu, userInfo } = props;
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  /*const showPreviewFeature = () => {
    console.log("In click");
  };*/
  return (
    <>
      <Menu
        id="user-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <Typography variant="subtitle2">
            {userInfo && userInfo["userName"] ? userInfo["userName"] : null}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setIsPreviewVisible(!isPreviewVisible)}>
          <ListItemIcon>
            <Preview />
          </ListItemIcon>
          <Typography variant="subtitle2">Preview Features</Typography>
        </MenuItem>
        <MenuItem style={{ color: "#eb2127" }}>
          <ListItemIcon>
            <Logout sx={{ color: "#eb2127" }} />
          </ListItemIcon>
          <Typography variant="subtitle2">Logout</Typography>
        </MenuItem>
      </Menu>
      {isPreviewVisible ? (
        <PreviewFeature
          isPreviewVisible={isPreviewVisible}
          setIsPreviewVisible={setIsPreviewVisible}
        />
      ) : null}
    </>
  );
};
