import React from "react";
import {
  Box,
  Divider,
  IconButton,
  List,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "../../base-components/Iconify";
import Scrollbar from "../../base-components/Scrollbar";
import { UserNotificationListItem } from "./UserNotificationListItem";

export const UserNotificationMenu = (props) => {
  const {
    anchorElUser,
    handleCloseUserNotificationsMenu,
    notifications,
    userInfo,
  } = props;
  return (
    <>
      <Popover
        id="user-notifications-menu"
        open={Boolean(anchorElUser)}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseUserNotificationsMenu}
        onClick={handleCloseUserNotificationsMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            width: 360,
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have 1 unread messages
            </Typography>
          </Box>
          <Tooltip title="Mark all as read">
            <IconButton color="success">
              <Iconify icon="eva:done-all-fill" width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider />
        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {notifications && notifications.length > 0
              ? notifications.map((data) => {
                  return <UserNotificationListItem notification={data} />;
                })
              : null}
          </List>
        </Scrollbar>
      </Popover>
    </>
  );
};
