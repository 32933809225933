import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

export const UserToolKit = (props) => {
  return (
    <Tooltip title="User Tool Kit">
      <IconButton
        size="large"
        aria-label="show user toolkit menu"
        className="icon-button"
      >
        <FontAwesomeIcon icon={faBriefcase} className="icon-color" />
      </IconButton>
    </Tooltip>
  );
};
